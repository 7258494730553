import React, { useRef, useState } from 'react'
import styled from 'styled-components'
import App from 'App'
import Alert from 'react-s-alert'
import { graphql, StaticQuery } from 'gatsby'
import * as Yup from 'yup'
import { Formik } from 'formik'
import ReCAPTCHA from 'react-google-recaptcha'
import { colors, fontSizes, space, radius, fontWeights, COLOR_CONSTANTS } from 'theme'
import { CONTAINER_MAX_WIDTH, ERROR_MESSAGE } from 'consts'
import { pxToRem } from 'helpers'
import SEO from 'components/SEO'
import { Flex, Grid, Box } from 'components/Layout'
import Container from 'components/Container'
import { H1, H2, Text } from 'components/Typography'
import Footer from 'components/Footer'
import LocalImage from 'components/LocalImage'
import ButtonWithLoading from 'components/ButtonWithLoading'
import BreadCrumbs from 'components/BreadCrumbs'
import TextArea from 'components/TextArea'
import Input from 'components/Input'
import DropDown from 'components/DropDown'
import RunningText from 'components/RunningText'
import imageHeader from 'static/images/social-media-tools/header_picture.webp'
import imageCopy from 'static/images/social-media-tools/copy.svg'

import { blogContentAddingJoinTemplate } from './helper'

import GetStartedSection from './GetStartedSection'

const Wrapper = styled(Flex)`
  position: relative;
  flex-direction: column;
  align-items: center;
  width: 100%;
  overflow-x: hidden;
`

const ArticleBody = styled.div`
  max-width: 730px;
  color: #231f20;

  figure {
    margin: ${space.m} 0;
    padding: ${space.s} 0;
    text-align: center;
    border-radius: ${radius.l};
  }

  img {
    max-width: 100%;
    height: auto;
    border-radius: ${radius.l};
  }

  font-size: ${fontSizes.xl};

  a {
    color: ${colors.primary};
    font-weight: ${fontWeights.medium};
  }

  h2 {
    margin-top: ${space.xl};
    margin-bottom: ${space.m};
    padding: 0 0 ${space.s} 0;
    color: ${COLOR_CONSTANTS.DENIM};
  }

  h3 {
    margin-top: ${space.l};
    margin-bottom: ${space.m};
    padding: ${space.m} 0 ${space.s} 0;
  }
`

const StyledButtonWithLoading = styled(ButtonWithLoading)`
  box-shadow: 0px 18px 24px -8px rgba(36, 130, 253, 0.32);
`

const StyledCaptionWrapper = styled(Flex)`
  border-radius: ${radius.l};
  background: ${COLOR_CONSTANTS.WHITE};
  padding: ${pxToRem(24)};
`

const StyledRunningTextWrapper = styled(Flex)`
  flex-direction: column;
  border-radius: ${radius.l};
  border: 1px solid rgba(109, 124, 143, 0.3);
  background: rgba(109, 124, 143, 0.05);
  padding: ${space.m};
`

const StyledCopyWrapper = styled(Flex)`
  align-items: center;
  justify-content: center;
  padding: ${pxToRem(12)};
  background: ${COLOR_CONSTANTS.WHITE};
  border: 1px solid #b6cef0;
  cursor: pointer;
  border-radius: ${radius.xl};
`

const { BRAND, BRAND_DESCRIPTION, BRAND_INSPIRATION, CUSTOMERS, TONE_VOICE } = {
  BRAND: 'brand',
  BRAND_DESCRIPTION: 'brand_description',
  BRAND_INSPIRATION: 'brand_inspiration',
  CUSTOMERS: 'customers',
  TONE_VOICE: 'tone_voice',
}

const DEFAULT_TONE_VOICE = { label: 'Default', value: 'default' }

export const TONE_OPTIONS = [
  { type: '', title: '😶', description: 'No tone' },
  { type: 'funny', title: '😂', description: 'Funny' },
  { type: 'formal', title: '🎩', description: 'Formal' },
  { type: 'informal', title: '🙂', description: 'Informal' },
  { type: 'promotional', title: '📣', description: 'Promotional' },
  { type: 'engaging', title: '💬', description: 'Engaging' },
  { type: 'assertive', title: '🤓', description: 'Assertive' },
  { type: 'catchy', title: '🧲', description: 'Catchy' },
  { type: 'inspirational', title: '🤩', description: 'Inspirational' },
  { type: 'shocking', title: '😲', description: 'Shocking' },
]

const TONE_VOICES = [
  { label: 'Assertive', value: 'assertive' },
  { label: 'Bold', value: 'bold' },
  { label: 'Catchy', value: 'catchy' },
  { label: 'Convincing', value: 'convincing' },
  DEFAULT_TONE_VOICE,
  { label: 'Engaging', value: 'engaging' },
  { label: 'Friendly and fun', value: 'friendly and fun' },
  { label: 'Formal', value: 'formal' },
  { label: 'Gen Z', value: 'gen z' },
  { label: 'Inclusive', value: 'inclusive' },
  { label: 'Informal', value: 'informal' },
  { label: 'Motivating', value: 'motivating' },
  { label: 'Promotional', value: 'promotional' },
  { label: 'Short and concise', value: 'short and concise' },
  { label: 'Shocking', value: 'shocking' },
]

const ValidationSchema = () => {
  const isDropdownValueRequiredShape = {
    value: Yup.string().required(),
    label: Yup.string().required(),
  }

  const data = {
    [BRAND]: Yup.string()
      .required(`Please enter your brand name.`)
      .max(100, 'Brand name is too long - should be 100 chars maximum.'),
    [BRAND_DESCRIPTION]: Yup.string().max(1000, 'Brand description is too long - should be 1000 chars maximum.'),
    [BRAND_INSPIRATION]: Yup.string().max(1000, 'Brands inspiration is too long - should be 1000 chars maximum.'),
    [CUSTOMERS]: Yup.string().max(250, 'Customers description is too long - should be 250 chars maximum.'),
    [TONE_VOICE]: Yup.object()
      .shape(isDropdownValueRequiredShape)
      .typeError('Tone voice is required'),
  }

  return Yup.object().shape(data)
}

const StyledTextWrapper = styled.div``

const BrandVoiceGenerator = () => {
  const recaptchaRef = useRef()

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [caption, setCaption] = useState()

  const onRecaptchaExpired = () => {
    console.log('recaptcha expired')
    recaptchaRef.current.execute()
  }

  const handleSubmitForm = async (values) => {
    try {
      let recaptcha

      if (recaptchaRef && recaptchaRef.current) {
        recaptcha = recaptchaRef.current.getValue()

        if (!recaptcha) {
          await recaptchaRef.current.executeAsync()

          recaptcha = recaptchaRef.current.getValue()

          recaptchaRef.current.reset()
        }
      } else {
        recaptcha = 'disabled'
      }

      if (!recaptcha || recaptcha === 'disabled') {
        Alert.error(`Error verifying reCAPTCHA, please try again or contact support.`, { timeout: 5000 })
      } else {
        setCaption(null)
        setIsSubmitting(true)

        const body = {
          [BRAND]: values[BRAND].trim(),
          [BRAND_DESCRIPTION]: values[BRAND_DESCRIPTION] ? values[BRAND_DESCRIPTION].trim() : undefined,
          [BRAND_INSPIRATION]: values[BRAND_INSPIRATION] ? values[BRAND_INSPIRATION].trim() : undefined,
          [CUSTOMERS]: values[CUSTOMERS] ? values[CUSTOMERS] : undefined,
          [TONE_VOICE]: values[TONE_VOICE] ? values[TONE_VOICE].value : undefined,
          recaptcha,
        }

        const res = await fetch(`${process.env.GATSBY_API_URL}/ai/brand-voice-generator`, {
          method: 'POST',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        })

        const response = await res.json()

        const { caption, error } = response || {}

        if (!response || error) {
          Alert.error(error || ERROR_MESSAGE, { timeout: 5000 })
        } else {
          setCaption({
            ...{
              id: new Date().getTime() + Math.floor(Math.random() * 1000),
              text: caption,
              isNew: true,
            },
          })
        }
      }
    } catch (error) {
      console.error(error)
      Alert.error(ERROR_MESSAGE, { timeout: 5000 })
    } finally {
      setIsSubmitting(false)
    }
  }

  const handleClickCopyCaption = async () => {
    try {
      await navigator.clipboard.writeText(caption.text)
      Alert.success(`Text has been copied`)
    } catch (err) {
      Alert.success(ERROR_MESSAGE)
    }
  }

  return (
    <StaticQuery
      query={graphql`
        {
          wordpressPost(slug: { eq: "internal-brand-voice-generator" }) {
            content
          }
        }
      `}
      render={(data) => {
        const { wordpressPost } = data || {}
        const { content } = wordpressPost || {}

        const updatedContent = blogContentAddingJoinTemplate({ content })

        return (
          <App fullHeader>
            <SEO
              title="Free Brand Voice Generator - Create brand voices with ease - Vista Social"
              description="Brand voice is the unique personality that your brand presents to the world."
            />

            <Wrapper>
              <Container
                pl={{ mobile: 'l', tablet: 'l' }}
                pr={{ mobile: 'l', tablet: 'l' }}
                position="relative"
                maxWidth={CONTAINER_MAX_WIDTH}
                width="100%"
                height="100%"
              >
                <Flex flexDirection="column" alignItems="center">
                  <Grid
                    mt="l"
                    mb="l"
                    pt={{ mobile: 0, desktop: 'm' }}
                    gridTemplateColumns={{ mobile: '1fr', desktop: '1fr 1fr' }}
                    gridGap="l"
                    width="100%"
                    alignItems="center"
                  >
                    <Flex width="100%" justifyContent="center" alignItems="center">
                      <Flex flexDirection="column">
                        <Flex width="100%" justifyContent="center" flexDirection="column">
                          <Flex
                            alignItems="center"
                            display={{ mobile: 'none', mobileLarge: 'flex' }}
                            justifyContent={{ mobile: 'center', desktop: 'flex-start' }}
                          >
                            <BreadCrumbs
                              data={[
                                { label: 'Vista Social', path: '/' },
                                { label: 'Free Tools', path: '/social-media-tools/' },
                                { label: 'Brand voice generator', path: '/social-media-tools/brand-voice-generator/' },
                              ]}
                            />
                          </Flex>
                          <Flex
                            mt="l"
                            flexDirection={{ mobile: 'row', desktop: 'column' }}
                            flexWrap="wrap"
                            justifyContent="center"
                          >
                            <H1
                              fontSize="4xl"
                              fontWeight="bold"
                              color={COLOR_CONSTANTS.DENIM}
                              textAlign={{ mobile: 'center', desktop: 'left' }}
                            >
                              <H1
                                as="span"
                                fontSize="4xl"
                                fontWeight="bold"
                                textAlign={{ mobile: 'center', desktop: 'left' }}
                              >
                                Free Brand Voice Generator
                              </H1>
                            </H1>
                          </Flex>
                          <H2
                            mt="m"
                            pt="s"
                            color="secondaryText"
                            fontSize="l"
                            textAlign={{ mobile: 'center', desktop: 'left' }}
                            fontWeight="normal"
                          >
                            Brand voice is the unique personality that your brand presents to the world. It must run
                            consistently through all your communications (social media, website, blog posts, emails,
                            advertisements) no matter which teams are handling which channels, and it mustn't change.
                          </H2>
                        </Flex>
                      </Flex>
                    </Flex>
                    <Flex width="100%" ml="auto" mr={{ mobile: 'auto', desktop: 0 }} mt={{ mobile: 'l', desktop: 0 }}>
                      <LocalImage
                        src={imageHeader}
                        width="100%"
                        mx="auto"
                        maxWidth="617px"
                        alt="Brand voice generator"
                      />
                    </Flex>
                  </Grid>
                </Flex>
              </Container>
            </Wrapper>

            <Wrapper bg={COLOR_CONSTANTS.SALT}>
              <Container
                pl={{ mobile: 'l', tablet: 'l' }}
                pr={{ mobile: 'l', tablet: 'l' }}
                py={{ mobile: 'l', tablet: 'xl' }}
                position="relative"
                maxWidth={CONTAINER_MAX_WIDTH}
                width="100%"
                height="100%"
              >
                <Flex maxWidth="920px" mx="auto" position="relative" flexDirection="column">
                  <Formik
                    initialValues={{
                      [BRAND]: '',
                      [BRAND_DESCRIPTION]: '',
                      [BRAND_INSPIRATION]: '',
                      [CUSTOMERS]: '',
                      [TONE_VOICE]: DEFAULT_TONE_VOICE,
                    }}
                    onSubmit={handleSubmitForm}
                    validationSchema={ValidationSchema}
                  >
                    {({ values, handleChange, handleSubmit, touched, errors, setFieldValue }) => (
                      <Box>
                        <Box>
                          <Input
                            label="For which brand do you want to create your Tone of Voice?"
                            placeholder="For example Vista Social"
                            values={values[BRAND]}
                            onChange={handleChange(BRAND)}
                            error={errors[BRAND] && touched[BRAND] && errors[BRAND]}
                            width="100%"
                          />
                        </Box>

                        <Box mt="m">
                          <TextArea
                            label="How would you describe your brand?"
                            placeholder="For example positive, informative, and helpful"
                            values={values[BRAND_DESCRIPTION]}
                            onChange={handleChange(BRAND_DESCRIPTION)}
                            error={errors[BRAND_DESCRIPTION] && touched[BRAND_DESCRIPTION] && errors[BRAND_DESCRIPTION]}
                            width="100%"
                            rows="4"
                          />
                        </Box>

                        <Box mt="m">
                          <TextArea
                            label="Which brands inspire you?"
                            placeholder="For example Nike, Oatly, and Patagonia."
                            values={values[BRAND_INSPIRATION]}
                            onChange={handleChange(BRAND_INSPIRATION)}
                            error={errors[BRAND_INSPIRATION] && touched[BRAND_INSPIRATION] && errors[BRAND_INSPIRATION]}
                            width="100%"
                            rows="4"
                          />
                        </Box>

                        <Box mt="m">
                          <Input
                            label="Who are your customers?"
                            placeholder="For example freelancers and companies."
                            values={values[CUSTOMERS]}
                            onChange={handleChange(CUSTOMERS)}
                            error={errors[CUSTOMERS] && touched[CUSTOMERS] && errors[CUSTOMERS]}
                            width="100%"
                          />
                        </Box>

                        <Box mt="m">
                          <DropDown
                            options={TONE_VOICES}
                            label="Tone of voice"
                            value={values[TONE_VOICE]}
                            onChange={(option) => {
                              setFieldValue(TONE_VOICE, option)
                            }}
                            error={errors[TONE_VOICE] && touched[TONE_VOICE] && errors[TONE_VOICE]}
                            openMenuOnFocus
                            menuPlacement="top"
                            height={pxToRem(40)}
                          />
                        </Box>

                        <Flex mt="m" justifyContent="flex-end">
                          <StyledButtonWithLoading isLoading={isSubmitting} onClick={handleSubmit} type="submit">
                            <Text>{isSubmitting ? 'Generating' : 'Generate'}</Text>
                          </StyledButtonWithLoading>
                        </Flex>
                      </Box>
                    )}
                  </Formik>

                  {caption && (
                    <StyledCaptionWrapper mt="xl" flexDirection="column" width="100%" position="relative">
                      <StyledRunningTextWrapper>
                        <Flex position="relative" overflow="auto">
                          <Box position="absolute" width="100%">
                            <RunningText
                              text={caption.text}
                              onFinish={() => {
                                caption.isNew = false
                                setCaption({ ...caption })
                              }}
                              isNew={caption.isNew}
                            />
                          </Box>
                          <Box zIndex="-1" bg={COLOR_CONSTANTS.WHITE}>
                            <Text>{caption.text}</Text>
                          </Box>
                        </Flex>
                        <Flex width="100%" justifyContent="flex-end">
                          <StyledCopyWrapper onClick={handleClickCopyCaption}>
                            <LocalImage src={imageCopy} width="24px" height="24px" />
                          </StyledCopyWrapper>
                        </Flex>
                      </StyledRunningTextWrapper>

                      <Text fontSize="s" mt="m" pt="s" color="secondaryText">
                        <Text fontSize="s" as="span" fontWeight="bold" color={COLOR_CONSTANTS.DENIM}>
                          Please note:
                        </Text>{' '}
                        This tool may display inaccurate or offensive material that doesn’t represent Vista Social's
                        views. You’re solely responsible for use of any content generated using this tool, including its
                        compliance with applicable laws and third party rights.
                      </Text>
                    </StyledCaptionWrapper>
                  )}
                </Flex>
              </Container>
            </Wrapper>

            {updatedContent && (
              <Wrapper bg={COLOR_CONSTANTS.WHITE}>
                <Container
                  pl={{ mobile: 'm', tablet: 'l' }}
                  pr={{ mobile: 'm', tablet: 'l' }}
                  position="relative"
                  maxWidth={CONTAINER_MAX_WIDTH}
                  width="100%"
                  height="100%"
                >
                  <Flex
                    mx="auto"
                    pl={{ mobile: 'l', tablet: 'l' }}
                    pr={{ mobile: 'l', tablet: 'l' }}
                    flexDirection="column"
                    alignItems="center"
                    position="relative"
                    maxWidth="1024px"
                    width="100%"
                  >
                    <ArticleBody className="post">
                      <StyledTextWrapper dangerouslySetInnerHTML={{ __html: updatedContent }} />
                    </ArticleBody>
                  </Flex>
                </Container>
              </Wrapper>
            )}

            <GetStartedSection />

            <Footer />

            <ReCAPTCHA
              sitekey="6Ld6gM0gAAAAAPwkW5CO806wVq_C1BanZ12XeZyI"
              size="invisible"
              ref={recaptchaRef}
              onExpired={onRecaptchaExpired}
            />
          </App>
        )
      }}
    />
  )
}

export default BrandVoiceGenerator
