import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { width, minWidth, space as styledSpace } from 'styled-system'
import { colors, space, fontSizes, radius, COLOR_CONSTANTS, fontWeights, fontStack } from 'theme'
import { Flex } from 'components/Layout'
import ErrorLabel from 'components/ErrorLabel'
import { Text } from './Typography'

const StyledTextArea = styled.textarea`
  padding: ${space.m};
  border: 1px solid ${({ isError }) => (isError ? colors.error : COLOR_CONSTANTS.WHITE)};
  border-radius: ${({ borderRadius }) => borderRadius}; 
  font-size: ${fontSizes.s};
  color: ${({ color }) => color || colors.primaryText};
  outline: 0;
  resize: none;
  font-family: ${fontStack}; 
  :focus {
    outline-style: none;
  }
  ::placeholder {
    color: ${COLOR_CONSTANTS.COSMIC_ENERGY};
    font-size: ${fontSizes.s};
    font-weight: ${fontWeights.normal};
  }
  ${width}
  ${minWidth}
  ${styledSpace}
`

const TextArea = ({ error, label, ...props }) => (
  <Flex flexDirection="column">
    {label && (
      <Text mb="xs" ml="xs" fontSize="m">
        {label}
      </Text>
    )}
    <StyledTextArea isError={error} {...props} />
    {error && <ErrorLabel mt="s" error={error} />}
  </Flex>
)

TextArea.defaultProps = {
  hasBoxShadow: false,
  borderRadius: radius.l,
  label: '',
  error: '',
}

TextArea.propTypes = {
  label: PropTypes.string,
  hasBoxShadow: PropTypes.bool,
  borderRadius: PropTypes.string,
  error: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
}

export default TextArea
